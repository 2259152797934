<template>
  <v-dialog
    :value="true"
    width="400px"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <validation-observer
      v-slot="{ invalid }"
      tag="form"
      @submit.prevent="onSubmit"
    >
      <v-card>
        <v-card-title>
          {{ $t("departureAddressDialog.title") }}
        </v-card-title>
        <v-card-subtitle>{{ location.address }}</v-card-subtitle>
        <v-card-text>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('departureAddressDialog.name')"
            rules="required"
            vid="name"
          >
            <v-text-field
              v-model.trim="name"
              autofocus
              :errors="errors"
              :label="$t('departureAddressDialog.name')"
            />
          </validation-provider>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn rounded text @click="$emit('close')">
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn color="primary" :disabled="invalid" rounded type="submit">
            {{ $t("common.add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: "",
    };
  },
  methods: {
    onSubmit() {
      this.$departureAddressRepo.create({
        name: this.name,
        address: this.location.address,
        lat: this.location.lat,
        lng: this.location.lng,
      });
      this.$emit("close");
    },
  },
};
</script>
