<template>
  <v-dialog v-if="place" fullscreen transition="" :value="true">
    <v-sheet class="d-flex fill-height flex-column">
      <v-toolbar class="flex-grow-0" color="transparent" flat>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="flex-grow-1 overflow-y-auto">
        <div class="text-h6">{{ place.name }}</div>
        <div>{{ place.address }}</div>
        <div>{{ cuisines }}</div>
        <div v-if="place.priceRange">{{ priceRange }}</div>
        <v-carousel
          v-if="images.length"
          class="rounded-xl"
          height="40vh"
          hide-delimiters
          :show-arrows="!isTouchDevice && images.length > 1"
        >
          <v-carousel-item v-for="image in images" :key="image.id">
            <div style="height: 100%; width: 100%">
              <tripadvisor-image :filepath="image.filepath" quality="o" />
            </div>
          </v-carousel-item>
        </v-carousel>
        <travel-time-list class="my-5" :place-id="place.id" :trip-id="tripId" />
        <open-tripadvisor-review-btn
          v-if="place.tripadvisorReviewUrl"
          :url="place.tripadvisorReviewUrl"
        />
      </v-container>
      <v-bottom-navigation>
        <directions-btn
          :destination="{ lat: place.lat, lng: place.lng }"
          :origin="{ lat: authTripUser.lat, lng: authTripUser.lng }"
          :travel-mode="authTripUser.travelMode"
        />
        <vote-btn :place-id="place.id" style="height: 100%" :trip-id="tripId" />
        <see-on-map-btn :place-id="place.id" :trip-id="tripId" />
        <choose-place-btn
          v-if="authTripUser.isAdmin"
          :place="place"
          :trip="trip"
        />
      </v-bottom-navigation>
    </v-sheet>
  </v-dialog>
</template>

<script>
import ChoosePlaceBtn from "@/components/buttons/ChoosePlaceBtn.vue";
import DirectionsBtn from "@/components/buttons/DirectionsBtn.vue";
import OpenTripadvisorReviewBtn from "@/components/OpenTripadvisorReviewBtn.vue";
import SeeOnMapBtn from "@/components/buttons/SeeOnMapBtn.vue";
import TravelTimeList from "@/components/TravelTimeList.vue";
import TripadvisorImage from "@/components/TripadvisorImage.vue";
import VoteBtn from "@/components/buttons/VoteBtn.vue";
import _orderBy from "lodash/orderBy";

export default {
  components: {
    ChoosePlaceBtn,
    DirectionsBtn,
    OpenTripadvisorReviewBtn,
    SeeOnMapBtn,
    TravelTimeList,
    TripadvisorImage,
    VoteBtn,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    authTripUser() {
      return this.$tripUserRepo
        .where("tripId", this.tripId)
        .where("userId", this.$userRepo.authUser.id)
        .first();
    },
    cuisines() {
      return this.place.cuisines
        .map((cuisine) => this.$t(`cuisines.${cuisine}`))
        .join(", ");
    },
    isTouchDevice() {
      return window.matchMedia("(pointer: coarse)").matches;
    },
    place() {
      return this.$placeRepo.with("images").find(this.placeId);
    },
    placeId() {
      return this.$route.params.placeId;
    },
    priceRange() {
      return this.$t(`priceRanges.${this.place.priceRange}`);
    },
    trip() {
      return this.$tripRepo.find(this.tripId);
    },
    images() {
      return _orderBy(this.place.images, "favorite", "desc");
    },
    tripId() {
      return this.$route.params.tripId;
    },
  },
  methods: {
    close() {
      const routeName = this.prevRoute?.name || "tripPlaces";
      this.$router.push({
        name: routeName,
        params: { tripId: this.tripId, placeId: this.placeId },
      });
    },
  },
};
</script>
