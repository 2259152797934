var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height"},[_c('v-main',{staticClass:"fill-height"},[(_vm.loading)?_c('v-container',{staticClass:"d-flex justify-center",attrs:{"fill-height":""}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":70}})],1):_c('v-container',{staticClass:"d-flex flex-column"},_vm._l((_vm.messages),function(message,index){return _c('div',{key:message.id,ref:"messages",refInFor:true,staticClass:"d-flex flex-column",attrs:{"id":`message-${message.id}`}},[(_vm.isNewDay(_vm.messages[index - 1], message))?_c('span',{staticClass:"align-self-center grey--text mb-2 text--darken-3 text-overline"},[_vm._v(" "+_vm._s(_vm.isoStringToLocalDate(message.created))+" ")]):_vm._e(),(message.isSystem)?_c('span',{staticClass:"align-self-center grey--text mb-1 text-caption text--darken-3"},[_vm._v(" "+_vm._s(message.text)+" ")]):_vm._e(),(!message.isSystem)?_c('div',{staticClass:"d-flex align-end",class:[
            _vm.isAuthUserMessage(message) ? 'justify-end' : 'justify-start',
            _vm.marginClass(_vm.messages[index - 1], message, _vm.messages[index + 1]),
          ],staticStyle:{"width":"100%"}},[(!_vm.isAuthUserMessage(message))?_c('user-avatar',{staticClass:"mr-2",attrs:{"user":message.sender}}):_vm._e(),_c('v-card',{staticClass:"px-3 py-1",class:[
              _vm.bubbleClass(_vm.messages[index - 1], message, _vm.messages[index + 1]),
            ],attrs:{"color":_vm.getColor(message),"max-width":_vm.bubbleMaxWidth,"width":'fit-content'}},[(_vm.isOtherUserMessage(message))?_c('div',[_vm._v(" "+_vm._s(message.sender.name)+" ")]):_vm._e(),_c('div',{staticClass:"message"},[_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.anchorme({
                    input: message.text,
                    options: {
                      attributes: {
                        target: '_blank',
                        referrerpolicy: 'no-referrer',
                      },
                    },
                  })
                )}}),(_vm.isLastMessage(message, _vm.messages[index + 1]))?_c('span',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.isoStringToLocalTime(message.created))+" ")]):_vm._e()])])],1):_vm._e(),(message.data?.pollId)?_c('poll-card',{staticClass:"align-self-center mb-4",attrs:{"poll-id":message.data.pollId}}):_vm._e()],1)}),0)],1),_c('v-footer',{attrs:{"app":""}},[_c('v-container',[_c('v-fab-transition',[(!_vm.isOnLastPage)?_c('v-btn',{staticClass:"scroll-btn",attrs:{"color":"pink","fab":"","x-small":""},on:{"click":_vm.scrollToBottom}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()],1),_c('form',{staticClass:"d-flex flex-grow-1",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"color":"primary","dense":"","hide-details":"","outlined":"","rounded":""},model:{value:(_vm.newMessageText),callback:function ($$v) {_vm.newMessageText=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"newMessageText"}}),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.newMessageText,"fab":"","small":"","type":"submit"}},[_c('v-icon',[_vm._v("mdi-send")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }