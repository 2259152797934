<template>
  <div class="d-flex align-center" style="margin-left: 10px">
    <user-avatar
      v-for="tripUser in tripUsers"
      :key="tripUser.user.id"
      :grayscale="!tripUser.joined"
      style="margin-left: -10px"
      :user="tripUser.user"
    />
    <span class="ml-2">{{ usernames }}</span>
  </div>
</template>

<script>
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  components: {
    UserAvatar,
  },
  props: {
    tripId: {
      type: String,
      required: true,
    },
  },
  computed: {
    tripUsers() {
      return this.$tripUserRepo
        .where((tripUser) => tripUser.tripId === this.tripId)
        .with("user")
        .orderBy((tripUser) => (tripUser.invited ? 1 : 0))
        .orderBy("invited")
        .get();
    },
    usernames() {
      const names = this.tripUsers.map((tripUser) => {
        return tripUser.user.isAuthenticated
          ? this.$t("tripCard.you")
          : tripUser.user.name;
      });
      const maxDisplayedNameCount = 6;
      if (names.length <= maxDisplayedNameCount) {
        return names.join(", ");
      }
      const remainingNameCount = names.length - maxDisplayedNameCount;
      return (
        names.slice(0, maxDisplayedNameCount - 1).join(", ") +
        ` + ${this.$t("tripCard.participants", {
          count: remainingNameCount + 1,
        })}`
      );
    },
  },
};
</script>
