<template>
  <div style="line-height: 0" :style="[cssVars, grayscaleStyle, { position }]">
    <img v-if="user.avatar" class="avatar-image" :src="user.avatar" />
    <div v-else-if="user.initials" class="avatar-initials">
      {{ user.initials }}
    </div>
    <v-icon v-else class="avatar-icon" large>
      {{ icon }}
    </v-icon>
    <v-icon v-if="adminBadge" class="adminBadge" small>mdi-crown</v-icon>
    <v-icon v-if="badgeIcon" class="badge" small>
      {{ badgeIcon }}
    </v-icon>
  </div>
</template>

<script>
export default {
  props: {
    adminBadge: {
      type: Boolean,
      default: false,
    },
    badgeIcon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "primary",
    },
    grayscale: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "mdi-account",
    },
    position: {
      type: String,
      default: "unset",
    },
    size: {
      type: String,
      default: "36",
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cssVars() {
      const color = ["primary", "secondary"].includes(this.color)
        ? this.$vuetify.theme.themes.light[this.color]
        : this.color;
      return {
        "--avatar-size": this.size + "px",
        "--avatar-color": color,
      };
    },
    grayscaleStyle() {
      return this.grayscale
        ? {
            filter: "grayscale(1)",
          }
        : {};
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-icon {
  background-color: var(--avatar-color);
  border-radius: 50%;
  color: map-get($shades, "white");
  height: var(--avatar-size);
  outline: solid 2px map-get($shades, "white");
  width: var(--avatar-size);
}
.avatar-image {
  border-radius: 50%;
  height: var(--avatar-size);
  outline: solid 2px map-get($shades, "white");
  width: var(--avatar-size);
}
.avatar-initials {
  align-items: center;
  background-color: var(--avatar-color);
  border-radius: 50%;
  color: map-get($shades, "white");
  display: flex;
  font-size: calc(var(--avatar-size) / 4);
  font-weight: 500;
  height: var(--avatar-size);
  justify-content: center;
  outline: solid 2px map-get($shades, "white");
  text-align: center;
  width: var(--avatar-size);
}
.adminBadge {
  background-color: map-get($shades, "white");
  border-radius: 50%;
  top: -5px;
  color: var(--v-secondary-base);
  padding: 2px;
  position: absolute;
  left: -5px;
}
.badge {
  background-color: var(--v-primary-base);
  border-radius: 50%;
  bottom: -5px;
  color: map-get($shades, "white");
  outline: solid 2px map-get($shades, "white");
  padding: 2px;
  position: absolute;
  right: -5px;
}
</style>
