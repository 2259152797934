<template>
  <div>
    <v-app-bar app color="transparent" flat>
      <go-back-btn :query="{ redirect: $route.query.redirect }" />
    </v-app-bar>
    <validation-observer
      ref="form"
      v-slot="{ invalid }"
      tag="form"
      @submit.prevent="onSubmit"
    >
      <v-main>
        <v-container>
          <div class="mb-5 text-h5">
            {{ $t("userResetPasswordStep3.title") }}
          </div>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('userResetPasswordStep3.password')"
            rules="required|min:10"
            vid="password"
          >
            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              autocomplete="new-password"
              :error-messages="showFieldErrors ? errors : []"
              :hint="$t('userResetPasswordStep3.passwordHint')"
              :label="$t('userResetPasswordStep3.password')"
              persistent-hint
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </validation-provider>
        </v-container>
      </v-main>
      <v-expand-transition>
        <v-footer v-if="!invalid" app>
          <v-container>
            <v-btn
              block
              class="my-3"
              color="primary"
              large
              rounded
              type="submit"
            >
              {{ $t("userResetPasswordStep3.action") }}
            </v-btn>
          </v-container>
        </v-footer>
      </v-expand-transition>
    </validation-observer>
  </div>
</template>

<script>
import { MiddlerAPIError } from "@/services/middler/errors.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import GoBackBtn from "@/components/buttons/GoBackBtn.vue";

export default {
  components: {
    GoBackBtn,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      name: "",
      password: "",
      showFieldErrors: false,
      showPassword: false,
    };
  },
  mounted() {
    if (!this.$route.query.phoneNumber || !this.$route.query.verificationCode) {
      this.$router.push({ name: "userResetPasswordStep1" });
    }
  },
  methods: {
    redirect() {
      this.$router.push(
        this.$route.query.redirect
          ? {
              path: this.$route.query.redirect,
            }
          : { name: "tripList" }
      );
    },
    async onSubmit() {
      this.showFieldErrors = true;
      const passwordResetted = await this.resetPassword();
      if (passwordResetted) {
        this.redirect();
      }
    },
    async resetPassword() {
      try {
        await this.$userRepo.service.resetPassword(
          this.$route.query.phoneNumber,
          this.$route.query.verificationCode,
          this.password
        );
        return true;
      } catch (error) {
        if (error instanceof MiddlerAPIError) {
          // Set errors for each field
          this.$refs.form.setErrors(error.data);
          return false;
        } else {
          throw error;
        }
      }
    },
  },
};
</script>
