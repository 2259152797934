<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    :width="width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <template #activator="{ on, attrs }">
      <v-textarea
        ref="locationInput"
        v-bind="attrs"
        auto-grow
        :label="label"
        readonly
        rows="1"
        :value="value ? value.address : ''"
        v-on="on"
        @click="search = ''"
      >
        <template v-if="value.address" #append>
          <v-tooltip bottom :disabled="$vuetify.breakpoint.mobile">
            <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
              <v-btn
                icon
                v-bind="tooltipAttrs"
                v-on="tooltipOn"
                @click="
                  isDepartureAddress(value)
                    ? (removeFavoriteConfirmDialog = true)
                    : (newDepartureAddress = value)
                "
              >
                <v-icon>
                  {{
                    isDepartureAddress(value) ? "mdi-star" : "mdi-star-outline"
                  }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{
                isDepartureAddress(value)
                  ? $t("locationDialog.removeFavorite")
                  : $t("locationDialog.addFavorite")
              }}
            </span>
          </v-tooltip>
        </template>
      </v-textarea>
      <departure-address-dialog
        v-if="newDepartureAddress"
        :location="newDepartureAddress"
        @close="
          newDepartureAddress = false;
          search = '';
        "
      />
      <confirm-dialog
        v-model="removeFavoriteConfirmDialog"
        :message="$t('locationDialog.removeFavoriteConfirmDialog.message')"
        @confirm="destroyDepartureAddress(value)"
      />
    </template>
    <v-card>
      <v-toolbar v-if="$vuetify.breakpoint.mobile" flat>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-textarea
          v-model="search"
          auto-grow
          autofocus
          clearable
          :placeholder="$t('locationDialog.searchField.placeholder')"
          rows="1"
        />
        <v-btn
          v-if="canGetCurrentPosition"
          block
          color="primary"
          :loading="getCurrentPositionLoading"
          outlined
          @click="getGeolocation"
        >
          <v-icon left>mdi-target</v-icon>
          Use current location
        </v-btn>
        <v-list v-if="results.length === 0">
          <div
            v-for="(departureAddress, index) in $departureAddressRepo.all()"
            :key="departureAddress.id"
          >
            <v-list-item
              two-line
              @click="onDepartureAddressClick(departureAddress)"
              @keydown.enter="onDepartureAddressClick(departureAddress)"
              @keydown.space="onDepartureAddressClick(departureAddress)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ departureAddress.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ departureAddress.address }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index < $departureAddressRepo.all().length - 1" />
          </div>
        </v-list>
        <v-list>
          <div v-for="(result, index) in results" :key="`result-${index}`">
            <v-list-item two-line @click="onResultClick(result)">
              <v-list-item-content>
                {{ result.address }}
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index < results.length - 1" />
          </div>
        </v-list>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { VInput } from "vuetify/lib";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";
import DepartureAddressDialog from "@/components/dialogs/DepartureAddressDialog.vue";
import GISService from "@/services/middler/GISService";

export default {
  components: {
    ConfirmDialog,
    DepartureAddressDialog,
  },
  extends: VInput,
  props: {
    language: {
      type: String,
      required: true,
    },
  },
  data() {
    const canGetCurrentPosition =
      Capacitor.isNativePlatform() || window.isSecureContext;

    return {
      canGetCurrentPosition,
      dialog: false,
      getCurrentPositionLoading: false,
      gisService: new GISService(),
      newDepartureAddress: false,
      removeFavoriteConfirmDialog: false,
      requestTimestamp: null,
      results: [],
      search: "",
    };
  },
  computed: {
    width() {
      const { name, thresholds } = this.$vuetify.breakpoint;
      return (thresholds[name] || 1920) * 0.5;
    },
  },
  watch: {
    search: {
      async handler(value) {
        const currentRequestTimestamp = Date.now();
        this.requestTimestamp = currentRequestTimestamp;

        if (!value) {
          this.results = [];
          return;
        }
        const proximity = "POINT(2.333333 48.866667)"; // Paris
        try {
          const data = await this.gisService.geocode(
            value,
            this.language,
            proximity
          );
          if (this.requestTimestamp === currentRequestTimestamp) {
            // if the request is still valid, update the results
            this.results = data.features.map((feature) => ({
              address: feature.properties.address,
              lat: feature.geometry.coordinates[1],
              lng: feature.geometry.coordinates[0],
            }));
          }
        } catch (error) {
          console.error(error); // TODO: handle errors nicely
        }
      },
    },
  },
  created() {
    this.$departureAddressRepo.list();
  },
  methods: {
    async getCurrentPosition() {
      this.getCurrentPositionLoading = true;
      if (Capacitor.isNativePlatform()) {
        return await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
        }).finally(() => {
          this.getCurrentPositionLoading = false;
        });
      } else if (navigator.geolocation) {
        return await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        }).finally(() => {
          this.getCurrentPositionLoading = false;
        });
      }
    },
    async getGeolocation() {
      const position = await this.getCurrentPosition();
      const point = `POINT(${position.coords.longitude} ${position.coords.latitude})`;
      this.gisService
        .reverseGeocode(point, this.language)
        .then((data) => {
          const feature = data.features[0];
          const location = {
            lat: feature.geometry.coordinates[1],
            lng: feature.geometry.coordinates[0],
          };
          location.address = feature.properties.address;
          this.$emit("input", location);
        })
        .catch((error) => {
          console.error(error); // TODO: handle errors nicely
        })
        .finally(() => {
          this.close();
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.dialog = false;
      });
    },
    destroyDepartureAddress(result) {
      const departureAddress = this.$departureAddressRepo
        .where("address", result.address)
        .first();
      this.$departureAddressRepo.remove(departureAddress);
    },
    isDepartureAddress(result) {
      return (
        this.$departureAddressRepo.where("address", result.address).first() !==
        null
      );
    },
    onDepartureAddressClick(departureAddress) {
      this.$emit("input", departureAddress);
      this.close();
    },
    onResultClick(result) {
      this.$emit("input", result);
      this.close();
    },
  },
};
</script>
