<template>
  <div>
    <v-bottom-sheet
      persistant
      :value="true"
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-sheet>
        <validation-observer
          v-slot="{ invalid }"
          tag="form"
          @submit.prevent="onSubmit"
        >
          <v-container class="d-flex flex-column py-5">
            <validation-provider rules="required">
              <v-text-field
                v-model.trim="name"
                autofocus
                :label="$t('tripMapUserAdd.nameInput.label')"
              />
            </validation-provider>
            <validation-provider rules="locationRequired">
              <location-dialog
                v-model="location"
                :label="$t('tripMapUserAdd.locationInput.label')"
                :language="deviceStore.language"
              />
            </validation-provider>
            <validation-provider rules="required">
              <travel-mode-toggle
                v-model="travelMode"
                :label="$t('tripMapUserAdd.modeSelect.label')"
              />
            </validation-provider>
            <v-btn
              class="align-self-end"
              color="primary"
              :disabled="invalid"
              rounded
              type="submit"
            >
              {{ $t("tripMapUserAdd.action") }}
            </v-btn>
          </v-container>
        </validation-observer>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapStores } from "pinia";
import { refreshPlaces } from "@/utils/place";
import { useDeviceStore } from "@/stores/device";
import LocationDialog from "@/components/dialogs/LocationDialog.vue";
import TravelModeToggle from "@/components/inputs/TravelModeToggle.vue";

export default {
  components: {
    LocationDialog,
    TravelModeToggle,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    const tripId = this.$route.params.tripId;
    const location = {
      lat: null,
      lng: null,
      address: null,
    };
    return {
      location,
      name: "",
      travelMode: null,
      tripId,
    };
  },
  computed: {
    ...mapStores(useDeviceStore),
  },
  methods: {
    async onSubmit() {
      await this.$tripRepo.addGuest(
        this.tripId,
        this.name,
        this.location.address,
        this.location.lat,
        this.location.lng,
        this.travelMode
      );
      refreshPlaces(this.tripId);
      this.close();
    },
    close() {
      this.$router.push({
        name: "tripMap",
        params: { tripId: this.tripId },
      });
    },
  },
};
</script>
