import BaseService from "@/services/middler/BaseService";

export default class UserService extends BaseService {
  basename = "users";

  async list(params) {
    const config = { params };
    const response = await this.client.get(this.basename, config);
    return response.data;
  }

  async listByTrip(tripId, params) {
    const config = { params };
    const path = `trips/${tripId}/users`;
    const response = await this.client.get(path, config);
    return response.data;
  }

  async retrieve(id, params) {
    const config = { params };
    const response = await this.client.get(`${this.basename}/${id}`, config);
    return response.data;
  }

  async partialUpdate(userId, changes, multipart) {
    let data = changes;
    const headers = {};
    if (multipart) {
      data = new FormData();
      for (const key in changes) {
        data.append(key, changes[key]);
      }
      headers[
        "Content-Type"
      ] = `multipart/form-data; boundary=${data._boundary}`;
    }
    const config = {
      headers,
    };
    const response = await this.client.patch(
      `${this.basename}/${userId}`,
      data,
      config
    );
    return response.data;
  }

  async isExists(phoneNumber) {
    const config = {
      params: {
        phone_number: phoneNumber,
      },
    };
    const response = await this.client.get(
      `${this.basename}/is_exists`,
      config
    );
    return response.data;
  }

  async filterByPhoneNumbers(phoneNumbers) {
    const data = {
      phoneNumbers,
    };
    const response = await this.client.post(
      `${this.basename}/filter_by_phone_numbers`,
      data
    );
    return response.data;
  }

  async login(phoneNumber, password) {
    const data = {
      phoneNumber,
      password,
    };
    const response = await this.client.post(`${this.basename}/login`, data);
    return response.data;
  }

  async logout() {
    await this.client.post(`${this.basename}/logout`);
  }

  async register(name, phoneNumber, verificationCode, password) {
    const data = {
      name,
      phoneNumber,
      verificationCode,
      password,
    };
    const response = await this.client.post(`${this.basename}/register`, data);
    return response.data;
  }

  async changePassword(password) {
    const data = {
      password,
    };
    await this.client.post(`${this.basename}/change_password`, data);
  }

  async resetPassword(phoneNumber, verificationCode, password) {
    const data = {
      phoneNumber,
      verificationCode,
      password,
    };
    await this.client.post(`${this.basename}/reset_password`, data);
  }

  async sendPhoneNumberVerificationCode(phoneNumber) {
    const data = { phoneNumber };
    await this.client.post(
      `${this.basename}/send_phone_number_verification_code`,
      data
    );
  }

  async verifyPhoneNumber(phoneNumber, verificationCode) {
    const data = { phoneNumber, verificationCode };
    await this.client.post(`${this.basename}/verify_phone_number`, data);
  }
}
