import { render, staticRenderFns } from "./TripPlaces.vue?vue&type=template&id=fdcdb960"
import script from "./TripPlaces.vue?vue&type=script&lang=js"
export * from "./TripPlaces.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.98.0__ejs@3.1.1_908eeb9cf70e8befba5563a6ffc1d939/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports