<template>
  <div>
    <v-app-bar app color="transparent" flat>
      <go-back-btn exact />
      <v-spacer />
      <v-toolbar-title class="mr-8">
        {{ $t("userPhoneNumber.title") }}
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <validation-observer
      v-slot="{ invalid }"
      tag="form"
      @submit.prevent="onSubmit"
    >
      <v-main>
        <v-container class="mt-4">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('userPhoneNumber.phoneNumber')"
            rules="required|phoneNumber"
            vid="phoneNumber"
          >
            <phone-number-input
              v-model="phoneNumber"
              :disabled="updating"
              :error-messages="errors"
            />
          </validation-provider>
        </v-container>
      </v-main>
      <v-expand-transition v-if="!invalid && hasChanged && !updating">
        <v-footer v-if="!invalid" app>
          <v-container>
            <v-btn
              block
              class="my-3"
              color="primary"
              large
              rounded
              type="submit"
            >
              {{ $t("userPhoneNumber.action") }}
            </v-btn>
          </v-container>
        </v-footer>
      </v-expand-transition>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapStores } from "pinia";
import { useLoadingStore } from "@/stores/loading";
import GoBackBtn from "@/components/buttons/GoBackBtn.vue";
import PhoneNumberInput from "@/components/inputs/PhoneNumberInput.vue";
import _clone from "lodash/clone";

export default {
  components: {
    GoBackBtn,
    PhoneNumberInput,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    const user = _clone(this.$userRepo.authUser);
    return {
      user,
      updating: false,
      phoneNumber:
        this.$route.query.phoneNumber || this.$userRepo.authUser.phoneNumber,
    };
  },
  computed: {
    ...mapStores(useLoadingStore),
    hasChanged() {
      return this.phoneNumber !== this.$userRepo.authUser.phoneNumber;
    },
  },
  async created() {
    if (this.$route.query.phoneNumber && this.$route.query.verificationCode) {
      this.updating = true;
      this.user.phoneNumber = this.$route.query.phoneNumber;
      this.user.verificationCode = this.$route.query.verificationCode;
      await this.$userRepo.update(this.user);
      this.$router.push({ name: "userSettings" });
    }
  },
  methods: {
    async onSubmit() {
      this.$router.push({
        name: "userSettingsPhoneNumberVerification",
        query: {
          phoneNumber: this.phoneNumber,
        },
      });
    },
  },
};
</script>
