<template>
  <v-card
    class="d-flex rounded-xl"
    height="20vh"
    :min-height="$vuetify.breakpoint.mobile ? '180px' : '220px'"
    @click="onCardClick"
  >
    <div style="height: 100%; width: 45%">
      <tripadvisor-image
        v-if="image"
        class="rounded-l-xl rounded-r-0"
        :filepath="image.filepath"
        quality="o"
      />
    </div>
    <div class="placeIndex">
      <span>{{ index + 1 }}</span>
    </div>
    <div class="card-right px-3 py-1" style="width: 55%">
      <div class="place-name truncate-two-lines">{{ place.name }}</div>
      <div v-if="!$vuetify.breakpoint.mobile" class="truncate-one-line">
        {{ place.address }}
      </div>
      <div class="mt-2 truncate-two-lines">{{ cuisines }}</div>
      <div v-if="place.priceRange">{{ priceRange }}</div>
      <transit-modes
        v-if="place.stationVehicleModes"
        :modes="place.stationVehicleModes"
      />
      <div class="travel-time-duration">
        <span class="icon">
          <v-icon color="white" size="18px">
            {{ travelModes[authTripUser.travelMode].icon }}
          </v-icon>
        </span>
        <span class="duration">
          {{ strfSeconds(durationByPlaceId[place.id]) }}
        </span>
      </div>
      <div class="vote-btn">
        <vote-btn color="primary" fab :place-id="place.id" :trip-id="tripId" />
      </div>
    </div>
  </v-card>
</template>

<script>
import { getTravelModes } from "@/constants/travelModes";
import TransitModes from "@/components/TransitModes.vue";
import TripadvisorImage from "@/components/TripadvisorImage.vue";
import VoteBtn from "@/components/buttons/VoteBtn.vue";
import _orderBy from "lodash/orderBy";
import strfSeconds from "@/utils/strfSeconds";

export default {
  components: {
    TransitModes,
    TripadvisorImage,
    VoteBtn,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    placeId: {
      type: String,
      required: true,
    },
    showMapBtn: {
      type: Boolean,
      default: true,
    },
    tripId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      strfSeconds,
    };
  },
  computed: {
    authTripUser() {
      return this.$tripUserRepo
        .where("tripId", this.tripId)
        .where("userId", this.$userRepo.authUser.id)
        .with("places")
        .first();
    },
    cuisines() {
      return this.place.cuisines
        .slice(0, 3)
        .map((cuisine) => this.$t(`cuisines.${cuisine}`))
        .join(", ");
    },
    durationByPlaceId() {
      return this.authTripUser.places.reduce((accumulator, place) => {
        accumulator[place.id] = place.pivot.duration;
        return accumulator;
      }, {});
    },
    image() {
      return this.images[0];
    },
    place() {
      return this.$placeRepo.with("images").find(this.placeId);
    },
    priceRange() {
      return this.$t(`priceRanges.${this.place.priceRange}`);
    },
    travelModes: getTravelModes,
    images() {
      return _orderBy(this.place.images, "favorite", "desc");
    },
  },
  methods: {
    onCardClick() {
      this.$router.push({
        name: "tripPlace",
        params: { tripId: this.tripId, placeId: this.placeId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.is-mobile {
  .card-right {
    font-size: 0.9rem;

    .place-name {
      font-size: 1.15rem;
    }
  }
}

.card-right {
  position: relative;

  .place-name {
    font-family: $heading-font-family;
    font-size: 1.25rem;
  }

  .travel-time-duration {
    bottom: 15px;
    left: 10px;
    position: absolute;

    .icon {
      background-color: var(--v-secondary-base);
      border-radius: 10px 0 0 10px;
      border: 1px solid var(--v-secondary-base);
      padding: 0.1em 0.4em 0.2em 0.5em;
    }
    .duration {
      border-radius: 0 10px 10px 0;
      border: 1px solid var(--v-secondary-base);
      padding: 0.1em 0.7em 0.2em 0.2em;
    }
  }

  .vote-btn {
    bottom: 10px;
    position: absolute;
    right: 10px;
  }
}
.placeIndex {
  align-items: center;
  background-color: map-get($shades, "white");
  border-radius: 50%;
  border: 2px solid var(--v-secondary-base);
  color: var(--v-secondary-base);
  display: flex;
  font-size: 18px;
  font-weight: bold;
  height: 30px;
  justify-content: center;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 30px;
}

.truncate-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate-two-lines {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  line-clamp: 2;
  overflow: hidden;
}
</style>
