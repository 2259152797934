<template>
  <v-item-group class="my-3" :value="value" @change="onChange($event)">
    <v-item v-slot="{ active, toggle }" value="food_establishment">
      <v-btn
        active-class="primary--text"
        class="mb-2 mr-2"
        :input-value="active"
        rounded
        @click="toggle"
      >
        <v-icon left>mdi-silverware-fork-knife</v-icon>
        {{ $t("placeTypes.foodEstablishment") }}
      </v-btn>
    </v-item>
    <v-item v-slot="{ active, toggle }" value="transit_station">
      <v-btn
        active-class="primary--text"
        class="mb-2 mr-2"
        :input-value="active"
        rounded
        @click="toggle"
      >
        <v-icon left>mdi-subway-variant</v-icon>
        {{ $t("placeTypes.transitStation") }}
      </v-btn>
    </v-item>
  </v-item-group>
</template>

<script>
import { VInput } from "vuetify/lib";

export default {
  extends: VInput,
  methods: {
    onChange(value) {
      this.$emit("input", value);
    },
  },
};
</script>
