import BaseService from "@/services/middler/BaseService";

export default class TripService extends BaseService {
  basename = "trips";

  async list(params) {
    const config = { params };
    const response = await this.client.get(this.basename, config);
    return response.data;
  }

  async create(data) {
    const response = await this.client.post(this.basename, data);
    return response.data;
  }

  async retrieve(id, params) {
    const path = `${this.basename}/${id}`;
    const config = { params };
    const response = await this.client.get(path, config);
    return response.data;
  }

  async partialUpdate(id, data) {
    const path = `${this.basename}/${id}`;
    const response = await this.client.patch(path, data);
    return response.data;
  }

  async delete(id) {
    const path = `${this.basename}/${id}`;
    await this.client.delete(path);
  }

  async invite(id, invitedUserId) {
    const path = `${this.basename}/${id}/invite`;
    const data = { invitedUserId };
    const response = await this.client.post(path, data);
    return response.data;
  }

  async join(id, shareKey) {
    const path = `${this.basename}/${id}/join`;
    const params = shareKey ? { share_key: shareKey } : {};
    const response = await this.client.post(path, null, { params });
    return response.data;
  }

  async addGuest(id, data) {
    const path = `${this.basename}/${id}/add_guest`;
    const response = await this.client.post(path, data);
    return response.data;
  }

  async route(tripId, userId, placeId) {
    const path = `${this.basename}/${tripId}/route`;
    const params = {
      user_id: userId,
      place_id: placeId,
    };
    const response = await this.client.get(path, { params });
    return response.data;
  }
}
