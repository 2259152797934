<template>
  <div>
    <v-snackbar v-model="noNearbyAddressSnackbar">
      {{ $t("tripMapUserEdit.noNearbyAddressAround") }}
    </v-snackbar>
    <v-snackbar v-model="outsideCoverageAreaSnackbar">
      {{ $t("tripMapUserEdit.outsideCoverageArea") }}
    </v-snackbar>
    <v-bottom-sheet
      v-model="sheet"
      persistant
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-sheet>
        <validation-observer
          v-slot="{ invalid }"
          tag="form"
          @submit.prevent="onSubmit"
        >
          <v-container
            class="d-flex flex-column py-5"
            style="position: relative"
          >
            <v-btn
              v-if="tripUser.user.isGuest"
              class="remove-btn"
              fab
              small
              @click="remove"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
            <validation-provider rules="required">
              <v-text-field
                v-model="user.name"
                :label="$t('tripMapUserEdit.nameInput.label')"
              />
            </validation-provider>
            <validation-provider rules="locationRequired">
              <location-dialog
                v-model="location"
                :label="$t('tripUserEdit.locationInput.label')"
                :language="deviceStore.language"
              />
            </validation-provider>
            <validation-provider rules="required">
              <travel-mode-toggle
                v-model="travelMode"
                :label="$t('tripUserEdit.modeSelectLabel')"
              />
            </validation-provider>
            <v-btn
              class="align-self-end"
              color="primary"
              :disabled="invalid || !hasChanged"
              rounded
              type="submit"
            >
              {{ $t("tripMapUserEdit.action") }}
            </v-btn>
          </v-container>
        </validation-observer>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { BusinessException } from "@/services/middler/errors";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapStores } from "pinia";
import { refreshPlaces } from "@/utils/place";
import { useDeviceStore } from "@/stores/device";
import GISService from "@/services/middler/GISService";
import LocationDialog from "@/components/dialogs/LocationDialog.vue";
import TravelModeToggle from "@/components/inputs/TravelModeToggle.vue";
import _clone from "lodash/clone";

export default {
  components: {
    LocationDialog,
    TravelModeToggle,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    const tripId = this.$route.params.tripId;
    const userId = this.$route.params.userId;
    const tripUser = this.$tripUserRepo
      .where("tripId", tripId)
      .where("userId", userId)
      .with("user")
      .first();
    const location = {
      lat: tripUser.lat,
      lng: tripUser.lng,
      address: tripUser.address,
    };
    const user = _clone(tripUser.user);
    return {
      gisService: new GISService(),
      location,
      noNearbyAddressSnackbar: false,
      outsideCoverageAreaSnackbar: false,
      sheet: false,
      travelMode: tripUser.travelMode,
      tripId,
      tripUser,
      user,
      userId,
    };
  },
  computed: {
    ...mapStores(useDeviceStore),
    hasChanged() {
      return (
        this.user.name !== this.tripUser.user.name ||
        this.location.address !== this.tripUser.address ||
        this.travelMode !== this.tripUser.travelMode
      );
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      async handler(query) {
        if (!query.lat || !query.lng) {
          this.sheet = true;
          return;
        }
        const point = `POINT(${query.lng} ${query.lat})`;
        try {
          const data = await this.gisService.reverseGeocode(
            point,
            this.deviceStore.language
          );
          if (!data.features.length) {
            this.noNearbyAddressSnackbar = true;
            setTimeout(() => {
              this.noNearbyAddressSnackbar = false;
              this.close();
            }, 1000);
            return;
          }
          this.sheet = true;
          const feature = data.features[0];
          this.location = {
            lat: feature.geometry.coordinates[1],
            lng: feature.geometry.coordinates[0],
            address: feature.properties.address,
          };
        } catch (error) {
          if (
            error instanceof BusinessException &&
            error.code === "outside_coverage_area"
          ) {
            this.outsideCoverageAreaSnackbar = true;
            setTimeout(() => {
              this.outsideCoverageAreaSnackbar = false;
              this.close();
            }, 1000);
          } else {
            throw error;
          }
        }
      },
    },
  },
  methods: {
    close() {
      this.$router.push({
        name: "tripMap",
        params: { tripId: this.tripId },
      });
    },
    async onSubmit() {
      await this.$userRepo.update(this.user);
      this.tripUser.address = this.location.address;
      this.tripUser.lat = this.location.lat;
      this.tripUser.lng = this.location.lng;
      this.tripUser.travelMode = this.travelMode;
      await this.$tripUserRepo.update(this.tripUser);
      refreshPlaces(this.tripId);
      this.close();
    },
    async remove() {
      await this.$tripUserRepo.remove(this.tripUser);
      refreshPlaces(this.tripId);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.remove-btn {
  position: absolute;
  top: -60px;
  right: 10px;
}
</style>
