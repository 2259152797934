import BaseService from "@/services/middler/BaseService";

export default class PlaceService extends BaseService {
  async list(tripId) {
    const params = new URLSearchParams();
    const path = `trips/${tripId}/places`;
    const response = await this.client.get(path, { params });
    return response.data;
  }

  async search(
    tripId,
    placeType,
    cuisines,
    priceRanges,
    vehicleModes,
    insideParis
  ) {
    const params = new URLSearchParams();
    if (placeType) {
      params.append("place_type", placeType);
    }
    if (cuisines) {
      cuisines.forEach((cuisine) => {
        params.append("cuisines", cuisine);
      });
    }
    if (priceRanges) {
      priceRanges.forEach((priceRange) => {
        params.append("price_ranges", priceRange);
      });
    }
    if (vehicleModes) {
      vehicleModes.forEach((mode) => {
        params.append("vehicle_modes", mode);
      });
    }
    if (insideParis) {
      params.append("inside_paris", insideParis);
    }
    const path = `trips/${tripId}/places/search`;
    const response = await this.client.get(path, { params });
    return response.data;
  }
}
