<template>
  <div class="background">
    <v-app-bar app color="primary" dark flat>
      <v-spacer />
      <language-select v-model="deviceStore.language" />
    </v-app-bar>
    <v-main class="fill-height">
      <v-container v-if="loading" class="d-flex fill-height justify-center">
        <v-progress-circular
          color="white"
          indeterminate
          :size="70"
        ></v-progress-circular>
      </v-container>
      <v-container
        v-else
        class="fill-height white--text"
        @click="hasStarted = false"
      >
        <div class="logo-container">
          <img
            alt="Middler"
            src="@/assets/images/logo.svg"
            style="width: 70%"
          />
          <div class="text-h7 text-lg-h6">Paris - Île-de-France</div>
        </div>
        <div class="carousel-container">
          <v-fade-transition>
            <v-carousel
              v-if="!hasStarted"
              v-model="carousel"
              class="text-h7 text-lg-h6 text-center"
              :continuous="false"
              cycle
              height="150"
              hide-delimiter-background
              interval="2000"
              :show-arrows="false"
            >
              <v-carousel-item>
                <v-sheet class="d-flex flex-column" color="transparent" dark>
                  <v-icon class="mb-3" large>mdi-subway-variant</v-icon>
                  <div>{{ $t("userAuth.find") }}</div>
                </v-sheet>
              </v-carousel-item>
              <v-carousel-item>
                <v-sheet class="d-flex flex-column" color="transparent" dark>
                  <v-icon class="mb-3" large>mdi-silverware-fork-knife</v-icon>
                  <div>{{ $t("userAuth.discover") }}</div>
                </v-sheet>
              </v-carousel-item>
              <v-carousel-item>
                <v-sheet class="d-flex flex-column" color="transparent" dark>
                  <v-icon class="mb-3" large>mdi-thumb-up</v-icon>
                  <div>{{ $t("userAuth.simplify") }}</div>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-fade-transition>
        </div>
      </v-container>
    </v-main>
    <v-footer v-if="!loading" app>
      <v-container>
        <v-btn
          v-if="!hasStarted"
          block
          class="my-3"
          color="primary"
          large
          rounded
          @click="hasStarted = true"
        >
          {{ $t("userAuth.start") }}
        </v-btn>
        <div v-if="hasStarted" class="text-center">
          <div>{{ $t("userAuth.newToMiddler") }}</div>
          <v-btn
            block
            class="my-3"
            color="primary"
            large
            rounded
            :to="{
              name: 'userSignUpStep1',
              query: { redirect: $route.query.redirect },
            }"
          >
            {{ $t("userAuth.signUp") }}
          </v-btn>
          <div class="my-1">~</div>
          <div>{{ $t("userAuth.alreadyHaveAnAccount") }}</div>
          <v-btn
            block
            class="my-3"
            color="primary"
            large
            outlined
            rounded
            :to="{
              name: 'userLogin',
              query: { redirect: $route.query.redirect },
            }"
            >{{ $t("userAuth.login") }}</v-btn
          >
          <div class="my-1">~</div>
          <v-btn
            block
            class="my-3 underline-text"
            large
            rounded
            text
            @click="continueAsAnonymous"
          >
            {{ $t("userAuth.continueAsAnonymous") }}
          </v-btn>
        </div>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import { NetworkError } from "@/services/middler/errors.js";
import { mapStores } from "pinia";
import { useDeviceStore } from "@/stores/device";
import LanguageSelect from "@/components/LanguageSelect.vue";
import Router from "vue-router";
import websocket from "@/websocket";

export default {
  components: {
    LanguageSelect,
  },
  data() {
    return {
      carousel: 0,
      hasStarted: false,
      loading: true,
    };
  },
  computed: {
    ...mapStores(useDeviceStore),
  },
  async mounted() {
    if (!this.$userRepo.authUser) {
      try {
        await this.$userRepo.retrieveAuthUser();
        await this.deviceStore.fetchOrCreate();
        websocket.reconnect();
        this.redirect();
      } catch (error) {
        if (!(error instanceof NetworkError)) {
          this.loading = false;
        } else {
          throw error;
        }
      }
    } else {
      this.redirect();
    }
  },
  methods: {
    async continueAsAnonymous() {
      await this.$userRepo.registerAnonymous();
      this.redirect();
    },
    redirect() {
      this.$router
        .push(
          this.$route.query.redirect
            ? {
                path: this.$route.query.redirect,
              }
            : { name: "tripList" }
        )
        .catch((error) => {
          if (
            !Router.isNavigationFailure(
              error,
              Router.NavigationFailureType.redirected
            )
          ) {
            throw error;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: var(--v-primary-base);
  height: 100%;
}
.carousel-container {
  height: 150px;
  width: 100%;
}
.logo-container {
  text-align: center;
  width: 100%;
}
.underline-text {
  text-decoration: underline;
  text-underline-offset: 3px;
}
</style>
