import { Model } from "pinia-orm";
import Trip from "@/models/Trip";
import TripUser from "@/models/TripUser";

export default class User extends Model {
  static entity = "users";
  static fields() {
    return {
      id: this.string(null),
      isAuthenticated: this.boolean(null),
      isGuest: this.boolean(null),
      name: this.string(""),
      email: this.string(null),
      avatar: this.string(null),
      defaultTravelMode: this.string("transit"),
      phoneNumber: this.string(null),
      trips: this.belongsToMany(Trip, TripUser, "userId", "tripId"),
    };
  }

  get initials() {
    return this.name
      .split(" ")
      .map((word) => word[0])
      .join("");
  }

  get isAnonymous() {
    return this.phoneNumber === null;
  }
}
