<template>
  <div class="fill-height">
    <v-app-bar app color="primary" dark flat>
      <v-fade-transition @after-leave="goToNextRoute">
        <img
          v-if="showLogo"
          alt="Middler"
          class="logo"
          src="@/assets/images/logo.svg"
        />
      </v-fade-transition>
      <v-spacer />
      <v-btn
        :aria-label="$t('tripList.settings')"
        dark
        icon
        @click="onSettingsClick"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-app-bar>
    <v-fade-transition>
      <v-main v-if="showMain" class="fill-height">
        <v-container
          v-if="loading && !trips.length"
          class="d-flex justify-center"
          fill-height
        >
          <v-progress-circular
            color="primary"
            indeterminate
            :size="70"
          ></v-progress-circular>
        </v-container>

        <v-container
          v-else-if="!loading && !trips.length"
          class="d-flex flex-column justify-center text-center"
          fill-height
        >
          <div class="text-h4 mb-7">{{ $t("tripList.emptyTitle") }}</div>
          <div>{{ $t("tripList.emptyDescription") }}</div>
        </v-container>

        <v-container v-else>
          <v-expansion-panels v-model="panel" accordion flat multiple>
            <v-expansion-panel v-show="upcomingTrips.length">
              <v-expansion-panel-header class="title">
                {{ $t("tripList.upcomingTrips") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <trip-card
                  v-for="trip in upcomingTrips"
                  :key="`upcoming-trip-${trip.id}`"
                  class="mb-4"
                  :trip-id="trip.id"
                  @click="onTripCardClick(trip.id)"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-show="pastTrips.length">
              <v-expansion-panel-header class="title">
                {{ $t("tripList.pastTrips") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <trip-card
                  v-for="trip in pastTrips"
                  :key="`past-trip-${trip.id}`"
                  class="mb-4"
                  :trip-id="trip.id"
                  @click="onTripCardClick(trip.id)"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-show="invitedTrips.length">
              <v-expansion-panel-header class="title">
                {{ $t("tripList.invitations") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <trip-card
                  v-for="trip in invitedTrips"
                  :key="`past-trip-${trip.id}`"
                  class="mb-4"
                  :trip-id="trip.id"
                  @click="onTripCardClick(trip.id)"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-main>
    </v-fade-transition>
    <v-expand-transition>
      <v-footer v-if="showFooter" app>
        <v-container>
          <v-btn
            block
            class="my-3"
            color="primary"
            large
            rounded
            :to="{ name: 'tripCreate' }"
          >
            {{ $t("tripList.newTrip") }}
          </v-btn>
        </v-container>
      </v-footer>
    </v-expand-transition>
  </div>
</template>

<script>
import { getUnreadMessageCount } from "@/utils/unreadMessageCount";
import { mapStores } from "pinia";
import { useLoadingStore } from "@/stores/loading";
import TripCard from "@/components/TripCard.vue";
import _sortBy from "lodash/sortBy";

export default {
  components: {
    TripCard,
  },
  data() {
    return {
      nextRoute: null,
      panel: [0, 2],
      showFooter: false,
      showLogo: true,
      showMain: false,
    };
  },
  computed: {
    ...mapStores(useLoadingStore),
    loading() {
      return (
        this.loadingStore["trip.list"] ||
        this.loadingStore["user.listRelatedUsers"]
      );
    },
    trips() {
      return this.$tripRepo.with("users").get();
    },
    joinedTrips() {
      return this.trips.filter((trip) => {
        return trip.users.some(
          (user) => user.id === this.$userRepo.authUser.id && user.pivot.joined
        );
      });
    },
    invitedTrips() {
      return _sortBy(
        this.trips.filter((trip) => {
          return trip.users.some(
            (user) =>
              user.id === this.$userRepo.authUser.id &&
              !user.pivot.joined &&
              new Date(trip.arrivalDatetime) >= new Date()
          );
        }),
        "arrivalDatetime"
      );
    },
    pastTrips() {
      return _sortBy(
        this.joinedTrips.filter((trip) => {
          return new Date(trip.arrivalDatetime) < new Date();
        }),
        "arrivalDatetime"
      ).reverse();
    },
    upcomingTrips() {
      return _sortBy(
        this.joinedTrips.filter((trip) => {
          return new Date(trip.arrivalDatetime) >= new Date();
        }),
        "arrivalDatetime"
      );
    },
  },
  async created() {
    await Promise.all([
      this.$mutedTripRepo.list(),
      this.$tripRepo.list().then(() => {
        getUnreadMessageCount();
      }),
      this.$userRepo.listRelatedUsers(),
    ]);

    // Check if the user is anonymous and if there are no trips
    if (this.$userRepo.authUser.isAnonymous && !this.$tripRepo.all().length) {
      // Create a new trip with the type "transit_station"
      const trip = await this.$tripRepo.create("food_establishment");
      // Go to the trip edit page
      this.$router.push({
        name: "tripUserEdit",
        params: { tripId: trip.id, userId: this.$userRepo.authUser.id },
      });
    }
  },
  mounted() {
    this.showFooter = true;
    this.showMain = true;
  },
  methods: {
    goToNextRoute() {
      this.$router.push(this.nextRoute);
    },
    onSettingsClick() {
      this.nextRoute = {
        name: "userSettings",
      };
      this.showLogo = false;
      this.showFooter = false;
      this.showMain = false;
    },
    onTripCardClick(tripId) {
      this.nextRoute = {
        name: "tripInfo",
        params: { tripId },
      };
      this.showLogo = false;
      this.showFooter = false;
      this.showMain = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 20px;
  left: 50%;
  position: fixed;
  transform: translateX(-50%);
}
</style>
