<template>
  <v-dialog persistent :value="dialog" :width="width">
    <v-card>
      <v-card-title class="text-h5">
        {{ $t("updaterDialog.title") }}
      </v-card-title>
      <v-card-text>
        <span v-if="!downloading && !downloadError">
          {{ $t("updaterDialog.message") }}
        </span>
        <div v-if="downloading">
          {{ $t("updaterDialog.downloading") }}
          <v-progress-linear indeterminate />
        </div>
        <v-scroll-y-transition>
          <v-alert v-if="downloadError" dense type="error">
            {{ $t("updaterDialog.downloadError") }}
          </v-alert>
        </v-scroll-y-transition>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="rounded-lg"
          color="primary"
          :disabled="downloadError || downloading || updating"
          large
          @click="update"
        >
          {{ $t("updaterDialog.action") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import { App as CapacitorApp } from "@capacitor/app";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { compareVersions } from "compare-versions";

export default {
  data() {
    return {
      dialog: false,
      downloadError: null,
      downloading: false,
      latest: null,
      updating: false,
    };
  },
  computed: {
    width() {
      const { name, thresholds } = this.$vuetify.breakpoint;
      return (thresholds[name] || 1920) * 0.5;
    },
  },
  async created() {
    if (Capacitor.isNativePlatform()) {
      CapacitorUpdater.notifyAppReady();
    }
    if (process.env.NODE_ENV === "production") {
      const state = await CapacitorApp.getState();
      if (state.isActive) {
        await this.checkForUpdate();
      }
      CapacitorApp.addListener("appStateChange", async (state) => {
        if (state.isActive) {
          await this.checkForUpdate();
        }
      });
    }
  },
  methods: {
    async fetchReleaseInfo() {
      const protocol = process.env.VUE_APP_URL_PROTOCOL;
      const host = process.env.VUE_APP_URL_HOST;
      return await fetch(
        `${protocol}://static.${host}/releases/latest.json`
      ).then((response) => response.json());
    },
    async checkForUpdate() {
      let currentVersion;
      if (Capacitor.isNativePlatform()) {
        const current = await CapacitorUpdater.current();
        currentVersion = await current.options?.version;
      }
      if (!currentVersion) {
        currentVersion = process.env.VUE_APP_VERSION;
      }
      this.latest = await this.fetchReleaseInfo();
      const hasUpdate =
        compareVersions(this.latest.version, currentVersion) == 1;
      if (hasUpdate) {
        if (Capacitor.isNativePlatform()) {
          // For native, show dialog
          this.dialog = hasUpdate;
        } else {
          // For web, update immediately
          this.update();
        }
      }
    },
    async update() {
      if (Capacitor.isNativePlatform()) {
        this.downloading = true;
        let bundle;
        try {
          bundle = await CapacitorUpdater.download({
            url: this.latest.url,
            version: this.latest.version,
            // checksum: this.latest.checksum,
          });
        } catch (error) {
          console.error(error);
          this.downloadError = error;
          setTimeout(() => {
            this.downloadError = null;
          }, 3000);
        } finally {
          this.downloading = false;
        }

        if (bundle) {
          this.updating = true;
          await CapacitorUpdater.set(bundle);
          this.updating = false;
        }
      } else {
        window.location.reload();
      }
    },
  },
};
</script>
